<template>
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<b>메뉴코드관리</b>
		</div>
		<!-- Search -->
		<div class="panel panel-flat">
			<!-- <div class="panel-heading">
				<h6 class="panel-title">
					<i class="icon-search4 position-left"></i>
					<b>검색조건</b>
				</h6>
				<div class="heading-elements">
					<ul class="icons-list">
						<li><a @click="pannelHidden" data-action="collapse"></a></li>
					</ul>
				</div>
			</div> -->
			<div class="panel-body" style="width: 1150px; margin: 20px 0px 0px auto">
				<form action="#" @submit.prevent="search">
					<div class="row">
						<div class="col-md-3" style="width: 250px">
							<div class="form-group">
								<label>{{ detailsFieldMap.menuCd }}</label>
								<input
									type="text"
									id="startInput"
									class="form-control"
									style="width: 150px"
									:placeholder="detailsFieldMap.menuCd"
									v-model="searchVM.menuCd"
									:maxlength="maxLength.menuCd"
								/>
							</div>
						</div>
						<div class="col-md-3" style="width: 280px">
							<div class="form-group">
								<label>{{ detailsFieldMap.menuNm }}</label>
								<input
									type="text"
									class="form-control"
									:placeholder="detailsFieldMap.menuNm"
									v-model="searchVM.menuNm"
									:maxlength="maxLength.menuNm"
								/>
							</div>
						</div>
						<div class="col-md-3" style="width: 400px">
							<div class="form-group">
								<label>{{ detailsFieldMap.callUrl }}</label>
								<input
									type="text"
									class="form-control"
									:placeholder="detailsFieldMap.callUrl"
									v-model="searchVM.callUrl"
									:maxlength="maxLength.callUrl"
								/>
							</div>
						</div>
						<div class="mb-10 text-right">
							<button type="submit" class="btn btn-labeled bg-primary">
								<b><i class="icon-search4"></i></b>
								검색
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<!-- Grid -->
		<div>
			<div class="mb-10 text-right">
				<button type="button" class="btn btn-labeled bg-teal mx-sm-1" @click="downloadExcel">
					<b><i class="icon-file-excel"></i></b>
					엑셀다운로드
				</button>
				<button type="button" class="btn btn-labeled bg-primary" @click="startCreate">
					<b><i class="icon-plus3"></i></b>
					추가
				</button>
			</div>

			<KendoGrid
				ref="grid"
				:auto-bind="true"
				:api-url="apiUrl.pageListApi"
				:columns="gridColumns"
				:apply-search-condition="applySearchStateOnGridLoad"
				@selected-row-item-changed="selectedRowItemChanged"
			></KendoGrid>

			<div class="mt-10 mb-15 text-right"></div>
		</div>

		<!-- Details -->
		<form id="detailsForm" action="#" class="form-horizontal form-validate-jquery" v-show="isEditMode || isCreateMode">
			<div class="panel panel-flat">
				<div class="panel-heading">
					<h6 class="panel-title">
						<i class="icon-checkmark3 position-left"></i>
						<b>상세정보</b>
					</h6>
					<div class="heading-elements">
						<ul class="icons-list">
							<li><a @click="pannelHidden" data-action="collapse"></a></li>
						</ul>
					</div>
				</div>
				<div class="panel-body panel-body-center">
					<div class="row panel-detail">
						<fieldset>
							<legend class="text-semibold col-md-12">
								<i class="icon-cog3 position-left"></i>
								메뉴코드관리 정보
							</legend>
							<div class="col-md-6">
								<!-- 메뉴코드, 수정모드만 표시 -->
								<div v-if="isEditMode">
									<jarvis-field
										:label="detailsFieldMap.menuCd"
										v-if="isEditMode"
										required="true"
										disabled="true"
										field="detailsItem.menuCd"
										v-model="detailsItem.menuCd"
										data-vv-name="detailsItem.menuCd"
									></jarvis-field>
								</div>
								<!-- 메뉴명 -->
								<jarvis-field
									:label="detailsFieldMap.menuNm"
									id="firstInputCreate"
									required="true"
									field="detailsItem.menuNm"
									v-model="detailsItem.menuNm"
									data-vv-name="detailsItem.menuNm"
									v-validate="validationRule.detailsItem.menuNm"
									:maxLength="maxLength.menuNm"
								></jarvis-field>
								<!-- 호출URL -->
								<jarvis-field
									:label="detailsFieldMap.callUrl"
									required="true"
									field="detailsItem.callUrl"
									v-model="detailsItem.callUrl"
									data-vv-name="detailsItem.callUrl"
									v-validate="validationRule.detailsItem.callUrl"
									:maxLength="maxLength.callUrl"
								></jarvis-field>
								<!-- 등록일, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.regDtm"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.regDtm"
									:value="detailsItem.regDtm | toDisplayDateTime"
								></jarvis-field>
								<!-- 등록자, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.regUser"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.regUser"
									:value="detailsItem.regUser"
								></jarvis-field>
								<!-- 수정일, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.updDtm"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.updDtm"
									:value="detailsItem.updDtm | toDisplayDateTime"
								></jarvis-field>
								<!-- 수정자, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.updUser"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.updUser"
									:value="detailsItem.updUser"
								></jarvis-field>
							</div>

							<div class="col-md-6" style="padding-left: 70px">
								<!-- 연결된 권한, 체크박스 목록 -->
								<div class="from-group">
									<label class="col-lg-3 control-label text-semibold" style="width: 120px">화면 권한</label>
									<div class="col-lg-9" style="text-align: left; width: 250px">
										<div class="checkbox" v-for="item in pageParam.roleGroupCodeOptions" :key="item.text">
											<label>
												<input type="checkbox" v-model="detailsItem.roleGrpCds" :value="item.value" />
												{{ item.value }} ({{ item.text }})
											</label>
										</div>
									</div>
								</div>
							</div>
						</fieldset>
					</div>

					<div class="row"></div>
					<!-- 버튼 추가모드만 표시 -->
					<div class="text-right" v-if="!isEditMode">
						<button type="button" class="btn btn-labeled mx-sm-1" @click="closeDetails">
							<b><i class="icon-cross"></i></b>
							닫기
						</button>
						<button type="button" class="btn btn-labeled bg-primary" @click="createData">
							<b><i class="icon-checkmark3"></i></b>
							저장
						</button>
					</div>
					<!-- 버튼 수정모드만 표시 -->
					<div class="text-right" v-if="isEditMode">
						<button type="button" class="btn btn-labeled mx-sm-1" @click="closeDetails">
							<b><i class="icon-cross"></i></b>
							닫기
						</button>
						<button type="button" class="btn btn-labeled bg-warning mx-sm-1" @click="deleteData">
							<b><i class="icon-minus3"></i></b>
							삭제
						</button>
						<button type="button" class="btn btn-labeled bg-primary" @click="updateData">
							<b><i class="icon-checkmark3"></i></b>
							저장
						</button>
					</div>

					<span v-if="debug">
						isCreateMode: {{ isCreateMode }}
						<br />
						isEditMode: {{ isEditMode }}
						<br />
						detailsItemOriginal: {{ detailsItemOriginal }}
						<br />
						detailsItem: {{ detailsItem }}
						<br />
						errors: {{ errors }}
						<br />
					</span>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import KendoGrid from '@/components/KendoGrid';
import _ from 'lodash';
import JarvisField from '@/components/JarvisField';
import apiIndex from '../../api/index';

let axiosExtention; //eslint-disable-line no-unused-vars
var menuApi = apiIndex.menu;

//상세필드검증규칙
const detailsValidationRule = {
	common: {
		//공통
		menuNm: 'max:50|required',
		callUrl: 'max:100|required',
	},
	edit: {
		//수정
	},
	create: {
		//생성
	},
};

const maxLength = {
	menuCd: '4',
	menuNm: '50',
	callUrl: '100',
};

_.extend(detailsValidationRule.edit, detailsValidationRule.common);
_.extend(detailsValidationRule.create, detailsValidationRule.common);

export default {
	components: {
		'jarvis-field': JarvisField,
		KendoGrid: KendoGrid,
	},
	created() {
		let params = JSON.parse(sessionStorage.getItem('menuPageParam'));
		// this.pageParam = JSON.parse(sessionStorage.getItem('menuPageParam'));
		if (!params) location.href = apiIndex.mainUrl;
		this.$validator.localize('ko', { attributes: this.$jarvisExtention.addKeyPrefix(this.detailsFieldMap, 'detailsItem.') });
		this.pageParam = {
			roleGroupCodeOptions: params.roleGroupCodeOptions,
		};
		axiosExtention = this.$jarvisExtention.axiosExtention;
	},
	mounted() {
		this.$nextTick(function () {
			this.focusFirstInput('startInput');
		});
	},
	data: () => ({
		pageParam: {
			roleGroupCodeOptions: '',
		},
		//API목록
		apiUrl: {
			excelApi: menuApi.exlMenu,
			pageListApi: menuApi.inqMenu,
			insUpdDelApi: menuApi.menu,
		},
		//검색VM
		searchVM: {
			menuCd: '',
			menuNm: '',
			callUrl: '',
		},
		//적용된 검색조건
		searchState: {},
		//그리드설정
		gridColumns: [
			{ field: 'menuCd', title: '메뉴코드', width: '10%', align: 'center' },
			{ field: 'menuNm', title: '메뉴명', width: '35%' },
			{ field: 'callUrl', title: '호출URL', width: '35%' },
			{
				field: 'regDtm',
				title: '등록일',
				width: '10%',
				align: 'center',
				dataFormat: 'YYYY-MM-DD',
			},
			{
				field: 'updDtm',
				title: '수정일',
				width: '10%',
				align: 'center',
				dataFormat: 'YYYY-MM-DD',
			},
		],
		//상세필드명맵
		detailsFieldMap: {
			menuCd: '메뉴코드',
			menuNm: '메뉴명',
			callUrl: '호출URL',
			regDtm: '등록일',
			regUser: '등록자',
			updDtm: '최종 수정일',
			updUser: '최종 수정자',
		},
		//상세편집 원본
		detailsItemOriginal: {},
		//상세편집 수정본
		detailsItem: {},
		maxLength,
		debug: false,
	}),
	computed: {
		isEditMode() {
			return !_.isEmpty(this.detailsItem) && !_.isEmpty(this.detailsItemOriginal);
		},
		isCreateMode() {
			return !_.isEmpty(this.detailsItem) && _.isEmpty(this.detailsItemOriginal);
		},
		validationRule() {
			var rule = null;
			if (this.isEditMode) {
				rule = detailsValidationRule.edit;
			} else {
				rule = detailsValidationRule.create;
			}
			return { detailsItem: rule };
		},
	},
	watch: {
		detailsItem(newVal) {
			this.$validator.reset(); //Validation상태 초기화
			//details활성화 시 스크롤 이동처리
			if (!_.isEmpty(newVal)) {
				this.$nextTick(function () {
					window.scrollTo({ top: this.getDetailsFormPositionForScrolling(), behavior: 'smooth' });
				});
			}
		},
	},
	methods: {
		getDetailsFormPositionForScrolling() {
			let element = document.getElementById('detailsForm');
			return element.getBoundingClientRect().top + window.scrollY;
		},
		focusFirstInput(inputId) {
			document.getElementById(inputId).focus();
		},
		pannelHidden(e) {
			// click event 에서 target pannel 가져오기
			let pannel = $(e.target).closest('.panel').find('.panel-body')[0];
			if (pannel) {
				if (pannel.style.display === 'none') {
					e.target.classList.remove('rotate-180');
					pannel.style.display = 'block';
				} else {
					e.target.classList.add('rotate-180');
					pannel.style.display = 'none';
				}
			}
		},
		//검색적용
		search() {
			this.searchState = $.extend(true /*deep*/, {}, this.searchVM);
			this.loadGrid();
		},
		//그리드로드
		loadGrid() {
			this.closeDetails();
			this.$refs.grid.load();
		},
		//그리드로드시 검색조건적용
		applySearchStateOnGridLoad(param) {
			return $.extend(true /*deep*/, param, this.searchState || {});
		},
		//엑셀다운로드
		downloadExcel() {
			this.$axios({
				url: this.apiUrl.excelApi,
				method: 'POST',
				data: this.searchState,
				responseType: 'blob', // important
			})
				.then(response => {
					let date = new Date();
					// 저장 파일 명 생성
					let fileName = '메뉴코드관리_' + date.getFullYear() + ('0' + (1 + date.getMonth())).slice(-2) + ('0' + date.getDate()).slice(-2) + '.xlsx';
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		//그리드선택처리
		selectedRowItemChanged(selectedRowItem) {
			if (!_.isEmpty(selectedRowItem)) {
				//수정시작
				this.$axios
					.post(this.apiUrl.pageListApi + '/' + selectedRowItem.menuCd, { menuCd: selectedRowItem.menuCd })
					.then(
						function (response) {
							this.errorMenuNm = '';

							this.original = response.data.menuNm;
							this.detailsItemOriginal = $.extend(true /*deep*/, {}, response.data);
							this.detailsItem = $.extend(true /*deep*/, {}, response.data);
							setTimeout(() => this.focusFirstInput('firstInputCreate'), 500);
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			} else {
				//상세화면 닫기 (다시로드등으로 선택변경)
				this.closeDetails();
			}
		},
		//상세화면 닫기
		closeDetails() {
			this.detailsItemOriginal = {};
			this.detailsItem = {};
		},
		//데이터 추가 시작
		startCreate() {
			this.detailsItemOriginal = {};
			this.detailsItem = {
				menuCd: '',
				menuNm: '',
				roleGrpCds: [],
			};
			setTimeout(() => this.focusFirstInput('firstInputCreate'), 500);
		},
		//데이터 수정 시작
		startEdit(menuCd) {
			const closure = this;
			this.$this.$axios
				.get(closure.apiUrl.pageListApi + '/' + menuCd)
				.then(function (response) {
					response;
					closure.detailsItemOriginal = $.extend(true /*deep*/, {}, response.data);
					closure.detailsItem = $.extend(true /*deep*/, {}, response.data);
				})
				.catch(axiosExtention.buildErrorHandler(closure.detailsFieldMap));
		},
		//신규저장
		createData() {
			const closure = this;
			this.$validator.validateAll().then(function (success) {
				if (success == false) {
					// console.log('validation.errors: ', closure.errors);
					return;
				}
				closure.$axios
					.post(closure.apiUrl.insUpdDelApi, closure.detailsItem)
					.then(function (response) {
						response;
						closure.loadGrid();
					})
					.catch(axiosExtention.buildErrorHandler(closure.detailsFieldMap));
			});
		},
		//수정저장
		updateData() {
			const closure = this;
			this.$validator.validateAll().then(function (success) {
				if (success == false) {
					// console.log('validation.errors: ', closure.errors);
					return;
				}
				if (confirm('수정한 데이터를 저장하시겠습니까?')) {
					const menuCd = closure.detailsItem.menuCd;
					closure.$axios
						.put(closure.apiUrl.insUpdDelApi + '/' + menuCd, closure.detailsItem)
						.then(function (response) {
							response;
							closure.loadGrid();
						})
						.catch(axiosExtention.buildErrorHandler(closure.detailsFieldMap));
				}
			});
		},
		//삭제
		deleteData() {
			if (!confirm('해당 데이터를 영구적으로 삭제하시겠습니까?')) {
				return;
			}
			const menuCd = this.detailsItem.menuCd;
			const closure = this;
			this.$axios
				.delete(closure.apiUrl.insUpdDelApi + '/' + menuCd)
				.then(function (response) {
					response;
					closure.loadGrid();
				})
				.catch(axiosExtention.buildErrorHandler());
		},
	},
};
</script>

<style scoped>
[v-cloak] > * {
	display: none !important;
}
#rootVM > .panel .panel-body .form-group {
	display: flex;
	align-items: center;
}
#rootVM > .panel .panel-body .form-group label {
	margin-top: 5px;
}
#rootVM > .panel .panel-body .form-group input {
	max-width: 200px;
	margin-left: 15px;
}
</style>
